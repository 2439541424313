import { Html, useProgress} from '@react-three/drei'
import { useEffect, useRef } from 'react'
import './assets-loader.scss'

const AssetsLoader = () => {
  const { active, errors, item, loaded, total } = useProgress()
  const progress = useRef()

  useEffect(() => {
    if (loaded !== total) {
      const calculatedProgress = Math.floor((loaded / total) * 100)
      progress.current = calculatedProgress
    }
  }, [loaded, total])

  return (
    <>
      <div className='assetsloader-component'>
        <div className='progress-bar'>
          <div
            style={{
              width: `${progress.current}%`,
              height: '0.3rem',
              background: 'rgba(139, 89, 255, 1)',
              borderRadius: '0.3rem',
            }}
          ></div>
          <p className='progress-text'>
            {item ? `LOADING:${progress.current}%` : 'Loading complete'}
          </p>
        </div>
      </div>
    </>
  )
}

export default AssetsLoader