import data from './Data'

const ServiceContent = ({ serviceContent }) => {
  return (
    <div className="content-container">
      {data.map((item, index) =>
        index === serviceContent ? (
            <div className="service-content" key={index}>
              <div className="service-content-img">
                <img src={item.img} alt="" key={index}/>
              </div>
              <div className="service-content-text">
                {item.content.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            </div>
        ) : null
      )}
    </div>
  )
}

export default ServiceContent
