import './about-us-mobile.scss'
import { about_us_mobile, fish } from 'public/assets/index'

const AboutUsMobile = () => {
  return (
    <div className="content-mobile">
      <img src={about_us_mobile} alt="" className="title" />
      <img src={fish} alt="" />
      <div className="desc">
        沙漠魚是一種擁有適應極端環境能力的生物，同時也是一種幻化於虛擬與真實之間的奇趣物種。我們希望以此來體現我們奮力向上的精神，且渴望穿梭於虛實之間的願景。另外，沙漠魚科技公司致力於開發未來的新型態社群平台，並透過結合公司既有的高規格投影設備與區塊鏈技術，真正實現虛實整合的元宇宙。
      </div>
    </div>
  )
}

export default AboutUsMobile
