import { Circle, MeshReflectorMaterial, useTexture } from '@react-three/drei'
import { useState, useEffect, useMemo } from 'react'
import { TextureLoader } from 'three'
import * as THREE from 'three'

export default function Ground({map}) {

  if (!map) {
    return null
  }

  return (
    <>
      <object3D rotation={[0, -1.68, 0]}>
        <Circle
          args={[30, 30, 30]}
          rotation-x={-Math.PI / 2}
          position={[0, 0.0, 0.5]}
          scale={12.97}
          receiveShadow
        >
          <MeshReflectorMaterial
            blur={[500, 100]}
            resolution={1024}
            mixBlur={0.8}
            mixStrength={20}
            depthScale={1}
            minDepthThreshold={0.85}
            map={map}
            metalness={0.9}
            roughness={1}
            transparent={true}
          />
        </Circle>
      </object3D>
    </>
  )
}
