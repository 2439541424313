import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useVideoTexture, Html, useTexture, useGLTF } from '@react-three/drei'
import Frame from 'react-frame-component'
import { angleToRadians } from '../../Utility/angle'
import { useRef } from 'react'
import { CameraContext } from '../Main/MainPage'
import { useControls } from 'leva'
import './house-iframe.scss'
import portfolioStyle from '../Main/NavPages/Portfolio/css/portfolio.css?inline'
import Portfolio from '../Main/NavPages/Portfolio/Portfolio'
import Ground from './Ground'
import { SandpockHouse } from './SandpockHouse'
import '../Main/NavPages/Logo/css/extra.scss'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import { TextureLoader } from 'three'
import { useLoader } from '@react-three/fiber'

function VideoMaterial({ url }) {
  const youtubeTexture = LiteYouTubeEmbed(url)
  console.log(youtubeTexture)
  const texture = useVideoTexture(LiteYouTubeEmbed(url))
  return <meshBasicMaterial map={texture} toneMapped={false} />
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} />
}

const House = ({ navProps, isMobile, html }) => {
  const [backBtn, closeBtn, floor] = useLoader(TextureLoader, [
    './assets/service_back_btn.svg',
    './assets/close_mini.png',
    './textures/Floor/Floor8K_Gradient_0502.png',
  ])

  console.log('house')
  let glbUrl
  if (isMobile) glbUrl = './models/House_0502_Mobile-v1.glb'
  else glbUrl = './models/House_0502_Output-v1.glb'

  const { nodes, materials, animations } = useGLTF(glbUrl)
  const { idle, updateGlobalState } = useContext(CameraContext)

  const equipmentRef = useRef()
  const serviceRef = useRef()
  const aboutUsRef = useRef()
  const portfolioRef = useRef()

  // const [path, setPath] = useState()
  const [eventName, setEvenName] = useState()
  const [onClick, setOnClick] = useState(false)

  useEffect(() => {
    if (eventName === 'back' || eventName === 'backBtn') {
      console.log(eventName)
      updateGlobalState({ isCameraMotion: true, isBackWard: true })
      localStorage.setItem('Path', 'Back')
    } else {
      console.log('click')
      updateGlobalState({ isCameraMotion: true })
      localStorage.setItem('Path', eventName)
      // setPath(eventName)
    }
  }, [onClick])

  localStorage.setItem('play', false)

  function ObjectSelector(event) {
    console.log(event.object.name)
    event.stopPropagation()
    // if (!isMobile) {
    if (event.object.name === 'EquipmentBanner') {
      setEvenName('Equipment')
    } else if (event.object.name === 'PortfolioBanner') {
      setEvenName('Portfolio')
    } else {
      setEvenName(event.object.name)
    }
    setOnClick(prev => !prev)

    // }
  }

  function backFunc() {
    updateGlobalState({ isBackWard: true })
  }

  const videoTex = useVideoTexture('./assets/HoaDongVideo.mp4')
  return (
    <>
      <object3D position={[0, -40, 0]} rotation={[0, angleToRadians(210), 0]}>
        <Ground map={floor} />
        <SandpockHouse
          updateGlobalState={updateGlobalState}
          nodes={nodes}
          materials={materials}
          animations={animations}
        />
        <mesh
          ref={equipmentRef}
          onClick={ObjectSelector}
          scale={15.2}
          position={[28.717, 13.4, 1.65]}
          rotation={[0, 1.57, 0]}
          name={'Equipment'}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <planeGeometry args={[1.1, 0.9]} />
          <meshBasicMaterial opacity={0} transparent={true} />
        </mesh>

        <mesh
          ref={serviceRef}
          onClick={ObjectSelector}
          scale={33.2}
          position={[-36.76, 45.844, 0]}
          rotation={[0, -1.57, 0]}
          name={'Service'}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <planeGeometry args={[1.65, 0.45]} position={[0, 0, 0.5]} />
          <meshBasicMaterial opacity={0} transparent={true} />
        </mesh>

        <mesh
          onClick={ObjectSelector}
          scale={27 - 0.33}
          position={[38.147 - 0.31, 49.785, -0.35 + 0.18]}
          rotation={[0, 1.57, 0]}
          name={'AboutUs'}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <planeGeometry args={[1.32, 0.85]} position={[2.2, 0, 0]} />
          <meshBasicMaterial map={videoTex} opacity={1} transparent={true} />
        </mesh>

        <mesh
          onClick={ObjectSelector}
          scale={26.8}
          position={[38.247, 41.285, 19]}
          rotation={[0, 1.57, 0]}
          name={'back'}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <planeGeometry args={[0.1, 0.048]} />
          <meshBasicMaterial map={backBtn} transparent={true} />
        </mesh>

        <mesh
          ref={portfolioRef}
          onClick={ObjectSelector}
          position={[-35.427, 18.3, -23.705]}
          rotation={[0, -1.57, 0]}
          name={'Portfolio'}
          scale={15}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <planeGeometry args={[0.63, 0.73]} />
          <meshBasicMaterial
            opacity={0}
            // color='red'
            transparent={true}
          />
          <Html
            transform
            wrapperClass="htmlPortfolio"
            distanceFactor={1.17}
            occlude="blending"
            pointerEvents={html}
          >
            <Frame>
              <style>{portfolioStyle}</style>
              <Portfolio navProps={navProps} />
            </Frame>
          </Html>

          <mesh
            onClick={ObjectSelector}
            scale={1}
            // position={[38.247, 41.285, 19]}
            position={[0.4, 0.3, 0]}
            rotation={[0, 0, 0]}
            name={'back'}
            onPointerOver={() => {
              document.body.style.cursor = 'pointer'
            }}
            onPointerOut={() => {
              document.body.style.cursor = 'auto'
            }}
          >
            <planeGeometry args={[0.1, 0.1]} />
            <meshBasicMaterial map={closeBtn} transparent={true} />
          </mesh>
        </mesh>
        <mesh
          ref={aboutUsRef}
          scale={99.9}
          position={[-2.3058, 36.998, -41.6]}
          rotation={[0, 3.1416, 0]}
          onPointerOver={() => {
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            document.body.style.cursor = 'auto'
          }}
        >
          <mesh
            position={[0, 0, 0.003]}
            onClick={ObjectSelector}
            name={'Extra'}
          >
            <planeGeometry args={[0.4668, 0.102]} />
            <meshBasicMaterial color={[0.045, 0.063, 0.074]} />
          </mesh>
          <meshBasicMaterial transparent opacity={0} />
        </mesh>
      </object3D>
    </>
  )
}

export default House
