import { useContext, useState } from 'react'
import NavBar from './NavBar'
import About from './NavPages/About'
import Equipment from './NavPages/Equipment/Equipment'
// import Service from './NavPages/Service/Service'
import Portfolio from './NavPages/Portfolio/Portfolio'
import PortfolioCard from './NavPages/Portfolio/PortfolioCard'
import Contact from './NavPages/Contact'
import SocialMediaLinks from './SocialMediaLinks'
import AudioPlayer from './AudioPlayer'
import { CameraContext } from './MainPage'
import { isBrowser, isMobile } from 'react-device-detect'
import NavBarMobile from './NavBarMobile'
import Service from './NavPages/Service/Service'

const MainUI = ({ navProps }) => {
  const {
    isAbout,
    setIsAbout,
    isNews,
    setIsNews,
    isPortfolioCard,
    setIsPortfolioCard,
    isService,
    setIsService,
    isContact,
    setIsContact,
  } = navProps

  const {
    globalState,
    updateGlobalState
  } = useContext(CameraContext)

  const closeAll = () => {
    setIsAbout(false)
    setIsPortfolioCard(false)
    setIsService(false)
    setIsContact(false)
  }

  const setCamera = path => {
    localStorage.setItem('Path', path)
    updateGlobalState(
      {
        isCameraMotion: true,
        pageState: path
      }
    )
  }

  const handleRouter = val => {
    switch (val) {
      case '/about-us':
        setIsAbout(true)
        setCamera('Extra')
        break
      case '/news':
        setIsAbout(true)
        setCamera('AboutUs')
        break
      case '/equipment':
        setCamera('Equipment')
        break
      case '/service':
        setIsService(true)
        setCamera('Service')
        break
      case '/portfolio':
        setCamera('Portfolio')
        break
      case '/contact':
        setIsContact(true)
        setCamera('Contact')
        break
      default:
        return
    }
  }

  return (
    <>
      {isMobile && (
        <>
          <NavBarMobile />
        </>
      )}
      {isBrowser && (
        <>
          <NavBar refreshUI={closeAll} nav={handleRouter} navProps={navProps} />
          {/* {isAbout && <AboutUs />} */}
          {globalState.modalContent === 'Equipment' && <Equipment />}
          {globalState.modalContent === 'Service' && <Service />}
          {globalState.modalContent === 'Extra' && <About />}
          {globalState.modalContent === 'Contact' && <Contact />}
          {isPortfolioCard && <PortfolioCard navProps={navProps} />}
          <SocialMediaLinks />
          {/* <AudioPlayer /> */}
        </>
      )}
    </>
  )
}

export default MainUI
