import { useState } from 'react'
import './service-mobile.scss'
import { service_mobile } from 'public/assets/index'
import data from '../NavPages/Service/Data'

const ServiceMobile = () => {
  console.log(data)
  const [isDetail, setIsDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleDetail = (item, i) => {
    setIsDetail(true)
    setSelectedItem(i)
  }
  return (
    <div className="content-mobile--contactus">
      <img src={service_mobile} alt="" className="title" />
      <div className="list-area">
        <ul className="list">
          {data.map((item, i) => (
            <li
              className={`list-item ${'item-' + i} ${
                selectedItem === i ? 'selected' : ''
              }`}
              onClick={() => {
                handleDetail(item, i)
              }}
            >
              <img src={item.imgMobile} alt="" className="service-img" />
              <p className="titleCn">{item.titleText}</p>
              {isDetail && selectedItem === i && (
                <div className="detail">
                  <div className="desc">{item.content}</div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ServiceMobile
