import { createContext, useState } from 'react'
import ThreeCanvas from '../ThreeCanvas'
import MainUI from './MainUI'

export const CameraContext = createContext()

function getDeviceType() {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )

  return mobile ? 'Mobile' : 'Desktop'
}

const MainPage = () => {

  console.log('MainPage')
  const [globalState, setGlobalState] = useState({
    idle: true,
    isCameraMotion: false,
    pageState: 'page state',
    isBackWard: false,
    navSlider: false,
    modalContent: 'main',
  })

  const [isAbout, setIsAbout] = useState(false)
  const [isNews, setIsNews] = useState(false)
  const [isPortfolioCard, setIsPortfolioCard] = useState(false)
  const [isService, setIsService] = useState(false)
  const [isContact, setIsContact] = useState(false)

  const device = getDeviceType()

  const navProps = {
    isAbout,
    setIsAbout,
    isNews,
    setIsNews,
    isPortfolioCard,
    setIsPortfolioCard,
    isService,
    setIsService,
    isContact,
    setIsContact,
  }

  function updateGlobalState(newState) {
    setGlobalState(globalState => ({
      ...globalState,
      ...newState,
    }))
  }

  return (
    <>
      <CameraContext.Provider
        value={{
          globalState,
          updateGlobalState,
        }}
      >
        <MainUI navProps={navProps} />
        <ThreeCanvas navProps={navProps} device={device} />
      </CameraContext.Provider>
    </>
  )
}

export default MainPage
