import { useState } from 'react'
import './portfolio-card.scss'
import { portfolio_card, close, portfolio_title } from 'public/assets'
import Accordion from '../../UI/Accordion/Accordion'
import { items } from './Data'
import SubLightBox from '../../UI/SubLightBox/SubLightBox'

const PortfolioCard = ({ navProps }) => {
  const { isPortfolioCard, setIsPortfolioCard } = navProps
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeContentIndex, setActiveContentIndex] = useState(0)

  const closeSelf = () => {
    setIsPortfolioCard(false)
  }

  return (
    <div className="portfolio-card">
      <div className="container">
        <img src={portfolio_card} alt="" className="bg" />
        <img src={close} alt="" className="close-btn" onClick={closeSelf} />
        <div className="card-title">
          <img src={portfolio_title} alt="" className="title-img" />
          <p className="main-title">實作案例</p>
        </div>
        <div className="content-container">
          <Accordion
            items={items}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            activeContentIndex={activeContentIndex}
            setActiveContentIndex={setActiveContentIndex}
          />
          <SubLightBox
            items={items}
            activeIndex={activeIndex}
            activeContentIndex={activeContentIndex}
          />
        </div>
      </div>
    </div>
  )
}

export default PortfolioCard
