import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGLTF, useAnimations, shaderMaterial } from '@react-three/drei'
import { ShaderMaterial, Uniform, TextureLoader } from 'three'
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useControls } from 'leva'
import { fragHeader, fragMain } from '../../Shader/mixFragmentShader'
import { vertHeader, vertMain } from '../../Shader/mixVertexShader'

let url;

export function SandpockHouse(props) {
  const mixFactor = useControls('mix factor', {
    factor: {
      value: 0.3,
      step: 0.002,
      min: 0.1,
      max: 1.0,
    },
  })

  const group = useRef()

  props.materials['M_Cat_Alpha'].transparent = true

  /*
    
      Blender export compressed gltf file with two texture
      Get the using texture from the compressed gltf file
      Define the property of custom material and set to custom material
    
  */

  const materialProps = useRef({
    shader: {
      alpha: null,
      light: null,
      house: null,
      custom: null,
    },
    reference: {
      alpha: props.materials['House_Alpha.007'].clone(),
      light: props.materials['House_Light.004'].clone(),
      house: props.materials['House_01'].clone(),
    },
    texture: {
      alpha: {
        base: null,
        bright: null,
      },
      light: {
        base: null,
        bright: null,
      },
      house: {
        base: null,
        bright: null,
      },
    },
  })

  /*

      Assgin the texture to the property of material
      Create custom material for replacing the original material
      Using OnBeforeCompile to create custom shader for custom material which extends from THREE.MeshBasicMaterial
    
  */

  const textureKeys = ['alpha', 'light', 'house']
  const mapTypes = ['base', 'bright']

  textureKeys.forEach(key => {
    mapTypes.forEach(type => {
      materialProps.current.texture[key][type] =
        materialProps.current.reference[key][
          type === 'base' ? 'map' : 'emissiveMap'
        ]
    })
  })

  function createCustomMaterial(key) {
    const customMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
    })

    customMaterial.onBeforeCompile = shader => {
      shader.uniforms.baseMap = {
        value: materialProps.current.texture[key].base,
      }
      shader.uniforms.brightMap = {
        value: materialProps.current.texture[key].bright,
      }
      shader.uniforms.mixFactor = { value: 0.5 }
      shader.vertexShader = vertHeader + shader.vertexShader
      shader.vertexShader = shader.vertexShader.replace(
        '#include <uv_vertex>',
        vertMain
      )
      shader.fragmentShader = fragHeader + shader.fragmentShader
      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <map_fragment>',
        fragMain
      )
      materialProps.current.shader[key] = shader
    }

    return customMaterial
  }

  const customAlphaMeshBasicMaterial = useRef(createCustomMaterial('alpha'))
  const customLightMeshBasicMaterial = useRef(createCustomMaterial('light'))
  const customHouseMeshBasicMaterial = useRef(createCustomMaterial('house'))

  props.materials['House_Alpha.007'] = customAlphaMeshBasicMaterial.current
  props.materials['House_Light.004'] = customLightMeshBasicMaterial.current
  props.materials['House_01'] = customHouseMeshBasicMaterial.current

  const nodesMemo = useMemo(() => props.nodes, [props.nodes])
  const materialsMemo = useMemo(() => props.materials, [props.materials])
  const animationsMemo = useMemo(() => props.animations, [props.animations])
  const { actions } = useAnimations(animationsMemo, group)
  const actionsMemo = useMemo(() => actions, [actions])
  materialsMemo.Banner.transparent=true;
  materialsMemo['Banner'].opacity = 0;


  useEffect(() => {
    for (const value of Object.values(actionsMemo)) {
      value.play()
    }
  }, [])

  useEffect(() => {
    if (
      materialProps.current.shader.alpha &&
      materialProps.current.shader.light &&
      materialProps.current.shader.house
    ) {
      materialProps.current.shader.alpha.uniforms['mixFactor'].value =
        mixFactor.factor
      materialProps.current.shader.light.uniforms['mixFactor'].value =
        mixFactor.factor
      materialProps.current.shader.house.uniforms['mixFactor'].value =
        mixFactor.factor
    }
  }, [mixFactor.factor])

  function mailboxOnclick(){
    props.updateGlobalState({isCameraMotion:true})
    localStorage.setItem('Path', 'Contact')
  }

  return (
    <>
      <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group 
          name="mailbox" 
          position={[-90, 1, 0]} 
          rotation={[0, 1.57, 0]}
          onClick={mailboxOnclick}
          onPointerEnter={()=>document.body.style.cursor = "pointer"}
          onPointerLeave={()=>document.body.style.cursor = "auto"}
          >
          <group name="PostBox1" position={[16.86, 0.13, 99.35]} rotation={[Math.PI / 2, -0.02, -Math.PI / 2]} scale={0.01}>
            <mesh castShadow receiveShadow name="Mail_R" geometry={nodesMemo.Mail_R.geometry} material={materialsMemo.Mail_R} />
            <mesh castShadow receiveShadow name="Mail_R_L" geometry={nodesMemo.Mail_R_L.geometry} material={materialsMemo.Mail_R} />
            <mesh castShadow receiveShadow name="Mail_R_R" geometry={nodesMemo.Mail_R_R.geometry} material={materialsMemo.Mail_R} />
          </group>
          <group name="PostBox2" position={[-1.59, 0.66, 117.71]} rotation={[Math.PI / 2, -0.01, -Math.PI / 2]} scale={0.01}>
            <mesh castShadow receiveShadow name="Mail_G" geometry={nodesMemo.Mail_G.geometry} material={materialsMemo.Mail_G} />
            <mesh castShadow receiveShadow name="Mail_G_L" geometry={nodesMemo.Mail_G_L.geometry} material={materialsMemo.Mail_G} />
            <mesh castShadow receiveShadow name="Mail_G_R" geometry={nodesMemo.Mail_G_R.geometry} material={materialsMemo.Mail_G} />
          </group>
        </group>
        <group name="motorcycle_01" position={[51.4, 4.3, 35]} rotation={[0, -Math.PI / 4, 0]} scale={6}>
          <group name="motocycle" position={[0, 0.01, 0.58]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <group name="Bag_L" position={[-0.03, -1.42, 1.81]} rotation={[0.02, -0.02, -0.01]}>
              <mesh castShadow receiveShadow name="Bag_cL" geometry={nodesMemo.Bag_cL.geometry} material={materialsMemo.lambert4} position={[5.24, 3.33, -1.81]} rotation={[-0.03, 0.04, -0.03]} />
              <mesh castShadow receiveShadow name="Bag_vL" geometry={nodesMemo.Bag_vL.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="Bag_R" position={[1.57, 0.01, -0.51]} rotation={[-0.01, 0, -0.02]}>
              <mesh castShadow receiveShadow name="Bag_cR" geometry={nodesMemo.Bag_cR.geometry} material={materialsMemo.lambert4} position={[6.59, -4.62, 2.03]} rotation={[0.02, 0, -0.07]} />
              <mesh castShadow receiveShadow name="Bag_vR" geometry={nodesMemo.Bag_vR.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="board">
              <mesh castShadow receiveShadow name="board001" geometry={nodesMemo.board001.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="boardtube_L1" geometry={nodesMemo.boardtube_L1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="boardtube_L2" geometry={nodesMemo.boardtube_L2.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="boardtube_R1" geometry={nodesMemo.boardtube_R1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="boardtube_R2" geometry={nodesMemo.boardtube_R2.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="injection_L" position={[-0.32, -0.16, -0.17]} rotation={[0, -0.01, 0.01]}>
              <mesh castShadow receiveShadow name="injection_L001" geometry={nodesMemo.injection_L001.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_L" geometry={nodesMemo.support_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_L1" geometry={nodesMemo.support_L1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_L2" geometry={nodesMemo.support_L2.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_L3" geometry={nodesMemo.support_L3.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_L4" geometry={nodesMemo.support_L4.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="supportube_L" geometry={nodesMemo.supportube_L.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="injection_R" position={[0.31, -0.13, 0.09]} rotation={[0, 0, -0.01]}>
              <mesh castShadow receiveShadow name="injection_R001" geometry={nodesMemo.injection_R001.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="injection_R1" geometry={nodesMemo.injection_R1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="injection_R2" geometry={nodesMemo.injection_R2.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="injection_R3" geometry={nodesMemo.injection_R3.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="injection_R4" geometry={nodesMemo.injection_R4.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="support_R" geometry={nodesMemo.support_R.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="supportube_R" geometry={nodesMemo.supportube_R.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="light">
              <mesh castShadow receiveShadow name="light_L" geometry={nodesMemo.light_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="light_R" geometry={nodesMemo.light_R.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="lighttube_L" geometry={nodesMemo.lighttube_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="lighttube_R" geometry={nodesMemo.lighttube_R.geometry} material={materialsMemo.lambert4} />
            </group>
            <group name="tube_under">
              <mesh castShadow receiveShadow name="tube_under001" geometry={nodesMemo.tube_under001.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_L" geometry={nodesMemo.tube_under_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_L1" geometry={nodesMemo.tube_under_L1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_L2" geometry={nodesMemo.tube_under_L2.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_R" geometry={nodesMemo.tube_under_R.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_R1" geometry={nodesMemo.tube_under_R1.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="tube_under_R2" geometry={nodesMemo.tube_under_R2.geometry} material={materialsMemo.lambert4} />
            </group>
            <mesh castShadow receiveShadow name="biglight" geometry={nodesMemo.biglight.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="body001" geometry={nodesMemo.body001.geometry} material={materialsMemo.lambert4}>
              <mesh castShadow receiveShadow name="oil_box" geometry={nodesMemo.oil_box.geometry} material={materialsMemo.lambert4} />
            </mesh>
            <mesh castShadow receiveShadow name="body_fly" geometry={nodesMemo.body_fly.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="body_fly_out" geometry={nodesMemo.body_fly_out.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="box" geometry={nodesMemo.box.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="center" geometry={nodesMemo.center.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="center_tube" geometry={nodesMemo.center_tube.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="chair" geometry={nodesMemo.chair.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="down_body" geometry={nodesMemo.down_body.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="handle" geometry={nodesMemo.handle.geometry} material={materialsMemo.lambert4}>
              <mesh castShadow receiveShadow name="brakes_L" geometry={nodesMemo.brakes_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="brakes_R" geometry={nodesMemo.brakes_R.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="meter_L" geometry={nodesMemo.meter_L.geometry} material={materialsMemo.lambert4} />
              <mesh castShadow receiveShadow name="meter_R" geometry={nodesMemo.meter_R.geometry} material={materialsMemo.lambert4} />
              <group name="mirror">
                <mesh castShadow receiveShadow name="mirror_L" geometry={nodesMemo.mirror_L.geometry} material={materialsMemo.lambert4} />
                <mesh castShadow receiveShadow name="mirror_R" geometry={nodesMemo.mirror_R.geometry} material={materialsMemo.lambert4} />
                <mesh castShadow receiveShadow name="mirrortube_L" geometry={nodesMemo.mirrortube_L.geometry} material={materialsMemo.lambert4} />
                <mesh castShadow receiveShadow name="mirrortube_R" geometry={nodesMemo.mirrortube_R.geometry} material={materialsMemo.lambert4} />
              </group>
            </mesh>
            <mesh castShadow receiveShadow name="horn" geometry={nodesMemo.horn.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="hyunwon" geometry={nodesMemo.hyunwon.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="hyunwon_big" geometry={nodesMemo.hyunwon_big.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="hyunwon_down" geometry={nodesMemo.hyunwon_down.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="L_tube" geometry={nodesMemo.L_tube.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="light_up" geometry={nodesMemo.light_up.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="R_tube" geometry={nodesMemo.R_tube.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="stuck1" geometry={nodesMemo.stuck1.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="stuck2" geometry={nodesMemo.stuck2.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="stuck_L" geometry={nodesMemo.stuck_L.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="stuck_R" geometry={nodesMemo.stuck_R.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="trian_down" geometry={nodesMemo.trian_down.geometry} material={materialsMemo.lambert4} />
            <mesh castShadow receiveShadow name="trian_up" geometry={nodesMemo.trian_up.geometry} material={materialsMemo.lambert4} />
          </group>
        </group>
        <group name="advertising_canvas002" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="BezierCurve002">
            <mesh castShadow receiveShadow name="Flag_rope_L_01002" geometry={nodesMemo.Flag_rope_L_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[3845.46, 2749.48, -3447.2]} rotation={[-Math.PI / 2, 0, 0]} />
            <mesh castShadow receiveShadow name="Flag_rope_R_01002" geometry={nodesMemo.Flag_rope_R_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[3870.6, -2594.62, -3348.42]} rotation={[-Math.PI / 2, 0, 0]} />
          </group>
          <mesh castShadow receiveShadow name="Flag_01002" geometry={nodesMemo.Flag_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[3893.34, 73.08, -3267.53]} rotation={[-3.11, -0.02, Math.PI / 2]}>
            <mesh castShadow receiveShadow name="Flag_bottle_L_01002" geometry={nodesMemo.Flag_bottle_L_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[748.52, 2693.1, -1419.87]} rotation={[1.61, 1.57, 0.01]} />
            <mesh castShadow receiveShadow name="Flag_bottle_R_01002" geometry={nodesMemo.Flag_bottle_R_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[919.18, -2544.72, -1960.45]} rotation={[1.62, 1.57, 0.02]} />
          </mesh>
        </group>
        <group name="BezierCurve" />
        <group name="Hank" position={[13, 0, 38]} scale={0.01}>
          <group name="Armature001" rotation={[Math.PI / 2, 0, 0]}>
            <primitive object={nodesMemo.Root} />
            <skinnedMesh castShadow receiveShadow name="Body001" geometry={nodesMemo.Body001.geometry} material={materialsMemo['Body_01.002']} skeleton={nodesMemo.Body001.skeleton} />
            <skinnedMesh castShadow receiveShadow name="checkpoint" geometry={nodesMemo.checkpoint.geometry} material={materialsMemo['Checkpoint_01.002']} skeleton={nodesMemo.checkpoint.skeleton} />
            <skinnedMesh castShadow receiveShadow name="coat001" geometry={nodesMemo.coat001.geometry} material={materialsMemo['Coat_02.002']} skeleton={nodesMemo.coat001.skeleton} />
            <skinnedMesh castShadow receiveShadow name="eyes" geometry={nodesMemo.eyes.geometry} material={materialsMemo['Eyes_01.002']} skeleton={nodesMemo.eyes.skeleton} />
            <skinnedMesh castShadow receiveShadow name="Hair_1" geometry={nodesMemo.Hair_1.geometry} material={materialsMemo['Hair_01.002']} skeleton={nodesMemo.Hair_1.skeleton} />
            <skinnedMesh castShadow receiveShadow name="Head_1" geometry={nodesMemo.Head_1.geometry} material={materialsMemo['Head_01.002']} skeleton={nodesMemo.Head_1.skeleton} />
            <skinnedMesh castShadow receiveShadow name="panties" geometry={nodesMemo.panties.geometry} material={materialsMemo['panties_01.002']} skeleton={nodesMemo.panties.skeleton} />
            <skinnedMesh castShadow receiveShadow name="ring" geometry={nodesMemo.ring.geometry} material={materialsMemo['Ring_01.002']} skeleton={nodesMemo.ring.skeleton} />
            <skinnedMesh castShadow receiveShadow name="shoe" geometry={nodesMemo.shoe.geometry} material={materialsMemo['shoe_01.002']} skeleton={nodesMemo.shoe.skeleton} />
            <skinnedMesh castShadow receiveShadow name="skull_1" geometry={nodesMemo.skull_1.geometry} material={materialsMemo['skull_01.002']} skeleton={nodesMemo.skull_1.skeleton} />
            <skinnedMesh castShadow receiveShadow name="vest_1" geometry={nodesMemo.vest_1.geometry} material={materialsMemo['vest.002']} skeleton={nodesMemo.vest_1.skeleton} />
          </group>
          <group name="IK" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <group name="L_F_ikHandle" position={[5.21, -6.66, -1.81]} rotation={[0, 0.04, 0]} />
            <group name="L_H_ikHandle" position={[5.21, -42.97, -1.81]} rotation={[0, 0.04, 0]} />
            <group name="R_H_ikHandle" position={[5.21, -13.88, -1.81]} rotation={[0, 0.04, 0]} />
          </group>
        </group>
        <group name="Amature_Cat" position={[17, 4, 33.9]} rotation={[Math.PI / 2, 0, 0]} scale={0.25}>
          <primitive object={nodesMemo.Bip_01} />
          <skinnedMesh castShadow receiveShadow name="CAT_01001" geometry={nodesMemo.CAT_01001.geometry} material={materialsMemo.M_Cat_Alpha} skeleton={nodesMemo.CAT_01001.skeleton} />
        </group>
        <group name="Armature_LuckyCat" position={[-17, 9.15, 32]} rotation={[Math.PI / 2, 0, 0]} scale={0.02}>
          <primitive object={nodesMemo.Root1} />
          <skinnedMesh castShadow receiveShadow name="bell001" geometry={nodesMemo.bell001.geometry} material={materialsMemo['M_LuckyCat.001']} skeleton={nodesMemo.bell001.skeleton} />
        </group>
        <group name="House" scale={0.01}>
          <mesh castShadow receiveShadow name="mesh" geometry={nodesMemo.mesh.geometry} material={materialsMemo.House_01} />
          <mesh castShadow receiveShadow name="mesh_1" geometry={nodesMemo.mesh_1.geometry} material={materialsMemo['House_Light.004']} />
          <mesh castShadow receiveShadow name="mesh_2" geometry={nodesMemo.mesh_2.geometry} material={materialsMemo['House_Alpha.007']} />
        </group>
        <mesh castShadow receiveShadow name="brand_A_01002" geometry={nodesMemo.brand_A_01002.geometry} material={materialsMemo['House_Light.004']} position={[-35.12, 28.24, 28.41]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
        <mesh castShadow receiveShadow name="brand_B_01002" geometry={nodesMemo.brand_B_01002.geometry} material={materialsMemo['House_Light.004']} position={[-30.17, 28.24, 33.43]} rotation={[-Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_01002" geometry={nodesMemo.fan_01002.geometry} material={materialsMemo['House_Alpha.007']} position={[-20.97, 52.35, -33.15]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_02002" geometry={nodesMemo.fan_02002.geometry} material={materialsMemo['House_Alpha.007']} position={[-21.03, 42.95, -33.15]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_03002" geometry={nodesMemo.fan_03002.geometry} material={materialsMemo['House_Alpha.007']} position={[31.38, 43.34, -18.76]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_04002" geometry={nodesMemo.fan_04002.geometry} material={materialsMemo['House_Alpha.007']} position={[31.38, 43.34, -5.98]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_05002" geometry={nodesMemo.fan_05002.geometry} material={materialsMemo['House_Alpha.007']} position={[31.38, 43.34, 7.46]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="fan_06002" geometry={nodesMemo.fan_06002.geometry} material={materialsMemo['House_Alpha.007']} position={[31.38, 43.34, 20.6]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="GAME_01002" geometry={nodesMemo.GAME_01002.geometry} material={materialsMemo['House_Light.004']} position={[-27.01, 9.31, 36.4]} rotation={[-0.34, 0, -Math.PI]} scale={0.01} />
        <mesh castShadow receiveShadow name="GAME_Chair002" geometry={nodesMemo.GAME_Chair002.geometry} material={materialsMemo['House_Light.004']} position={[-24.47, 6.06, 42.06]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.01} />
        <mesh castShadow receiveShadow name="haircut_01002" geometry={nodesMemo.haircut_01002.geometry} material={materialsMemo['House_Light.004']} position={[26.64, 23.62, 31.35]} scale={0.01} />
        <mesh castShadow receiveShadow name="lantern_01002" geometry={nodesMemo.lantern_01002.geometry} material={materialsMemo['House_Light.004']} position={[34.25, 33, 29.83]} scale={0.01} />
        <mesh castShadow receiveShadow name="prohibit_01002" geometry={nodesMemo.prohibit_01002.geometry} material={materialsMemo['House_Light.004']} position={[-33.43, 7.21, 10.87]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh castShadow receiveShadow name="AboutUsBanner" geometry={nodesMemo.AboutUsBanner.geometry} material={materialsMemo.Banner} scale={0.01} />
        <mesh castShadow receiveShadow name="ServiceBanner" geometry={nodesMemo.ServiceBanner.geometry} material={materialsMemo.ServiceBanner} scale={0.01} />
        <mesh castShadow receiveShadow name="EquipmentBanner" geometry={nodesMemo.EquipmentBanner.geometry} material={materialsMemo.ServiceBanner} scale={0.01} />
      </group>
    </group>
    </>
  )
}
