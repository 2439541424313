export const fragHeader = `
    varying vec2 vUv;\n
    uniform sampler2D baseMap;\n
    uniform sampler2D brightMap;\n
    uniform float mixFactor;\n
    #pragma glslify: blend = require(glsl-blend/lighten);\n
`

export const fragMain = `
    vec4 texA = texture2D(baseMap, vUv);
    vec4 texB = texture2D(brightMap, vUv);
    vec4 mixColor = mix(texA, texB, mixFactor);
    diffuseColor = mixColor;
`
