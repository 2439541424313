export const navLinks = [
  {
    id: 'about-us',
    title: '關於我們',
    titleEn: 'ABOUT US',
  },
  {
    id: 'news',
    title: '最新消息',
    titleEn: 'NEWS',
  },
  {
    id: 'equipment',
    title: '專業設備',
    titleEn: 'EQUIPMENT',
  },
  {
    id: 'service',
    title: '專業服務',
    titleEn: 'SERVICE',
  },
  {
    id: 'portfolio',
    title: '實作案例',
    titleEn: 'PORTFOLIO',
  },
  {
    id: 'contact',
    title: '聯繫我們',
    titleEn: 'CONTACT',
  },
]

export const navLinksMobile = [
  {
    id: 'about-us',
    title: '關於我們',
    titleEn: 'ABOUT US',
  },
  {
    id: 'equipment',
    title: '專業設備',
    titleEn: 'EQUIPMENT',
  },
  {
    id: 'service',
    title: '專業服務',
    titleEn: 'SERVICE',
  },
  {
    id: 'portfolio',
    title: '實作案例',
    titleEn: 'PORTFOLIO',
  },
  {
    id: 'contact',
    title: '聯繫我們',
    titleEn: 'CONTACT',
  },
]
