import { useContext, useState } from 'react'
import ServiceContent from './ServiceContent'
import ServiceNav from './ServiceNav'
import './scss/service.scss'
import { CameraContext } from '../../MainPage'

const Service = () => {
  const [serviceContent, setServiceContent] = useState(0)
  const { updateGlobalState } = useContext(CameraContext)

  const itemIndexHandler = index => {
    if (index === 5) {
      localStorage.setItem('Path', 'Back')
      updateGlobalState({isBackWard:true})
    }
    setServiceContent(index)
  }

  return (
    <div className="service-component">
      <ServiceNav itemIndexHandler={itemIndexHandler} />
      <ServiceContent serviceContent={serviceContent} />
    </div>
  )
}

export default Service
