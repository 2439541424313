import { tiger, tiger_mask, tiger_title } from 'public/assets'

const Portfolio = ({ navProps }) => {
  const { isPortfolioCard, setIsPortfolioCard } = navProps
  const showAllPortfolio = () => {
    setIsPortfolioCard(true)
  }

  return (
    <div className="portfolio">
      <img src={tiger} alt="" className="bg" />
      <img src={tiger_mask} alt="" className="bg" />
      <div className="title-area">
        <img src={tiger_title} alt="" className="title" />
      </div>
      <div className="more-btn" onClick={showAllPortfolio}>
        <p>更多作品</p>
      </div>
    </div>
  )
}

export default Portfolio
