import { Canvas, useThree } from '@react-three/fiber'
import React, { Suspense, useEffect, useState } from 'react'
import Sandpock from './Sandpock'
import { ACESFilmicToneMapping, PCFSoftShadowMap, sRGBEncoding } from 'three'
import { OrbitControls, SoftShadows } from '@react-three/drei'
import { isMobile } from 'react-device-detect'
import Controller from '../Controller'
import AssetsLoader from './Browser/AssetsLoader'
import { Leva } from 'leva'
import MobileController from '../MobileController'

function ThreeCanvas({ navProps, device }) {
  const [enableHTML, setEnableHTML] = useState('none')
  return (
    <>
      <Suspense fallback={<AssetsLoader />}>
        <Canvas
          shadows
          className="three-canvas"
          dpr={[1, 1.5]}
          camera={{
            fov: 40,
            near: 0.1,
            far: 10000,
            stencilWrite: true,
            depthTest: true,
            position: [180.5 * -2, 0, 105.5 * -3.8],
          }}
          onCreated={({ gl }) => {
            gl.setPixelRatio(window.devicePixelRatio)
            gl.outputEncoding = sRGBEncoding
            // gl.physicallyCorrectLights = true
            gl.shadowMap.enabled = true
            gl.shadowMap.type = PCFSoftShadowMap
            gl.toneMapping = ACESFilmicToneMapping
            gl.logarithmicDepthBuffer = true
          }}
          // performance={{
          //   current: 1,
          //   min: 0.5,
          //   max: 1,
          //   debounce: 200,
          //   regress: () => void
          // }}
          // frameloop='demand'
        >
          <SoftShadows focus={0} samples={50} />
          <color attach="background" args={['#000']} />
          <fog attach="fog" args={['#01030A', 300, 650]} />
          <Sandpock navProps={navProps} device={device} html={enableHTML} />
          {!isMobile && (
            <Controller isMobile={isMobile} setEnableHTML={setEnableHTML} />
          )}
          {isMobile && <MobileController />}
          <Leva hidden />
        </Canvas>
      </Suspense>
    </>
  )
}

export default ThreeCanvas
