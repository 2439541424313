import { useContext, useEffect, useState } from 'react'
import data from './Data'

const EquipmentContent = ({selectedEquipment, setSelectedEquipment}) =>{

    useEffect(()=>{
        console.log(selectedEquipment);
    },[selectedEquipment])

    return(
        
        <div className='equipment-content'>
            <div className='nav'>
                {data.map((item, index)=>
                   index === selectedEquipment ? (
                    <h1>{item.title}</h1>
                   ):null 
                )}
                {data.map((item, index)=>
                    index === selectedEquipment ? (
                        <p>{item.subTitle}</p>
                    ):null
                )}
            </div>
            <div className='main-content'>
                {data.map((item, index)=>
                    index === selectedEquipment ? (
                      item.isImg? <img src={item.url} alt='' /> : <iframe src={item.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    ):null
                )}
            </div>
            <div className="footer">
                <img id="back" src="./assets/back_btn_circle.svg" alt="" onClick={()=>setSelectedEquipment(null)}/>
                <div className="line"></div>
                <div className="content">
                    {data.map((item, index)=>
                        index === selectedEquipment ? (
                            <p>{item.content}</p>
                        ):null
                    )}
                </div>
            </div>
        </div>
    )
}

export default EquipmentContent
