import React, { useEffect, useState } from 'react'
import {
  samoi,
  menu_mobile,
  menu_close_mobile,
  deco_orange,
  logo_white,
  fb_mobile,
  ins_mobile,
  twitter_mobile,
  sound_mobile,
} from 'public/assets'
import './nav-bar-mobile.scss'
import { navLinksMobile } from '../../Constants'
import AboutUsMobile from './MobileNavPages/AboutUsMobile'
import NewsMobile from './MobileNavPages/NewsMobile'
import EquipmentMobile from './MobileNavPages/EquipmentMobile'
import ServiceMobile from './MobileNavPages/ServiceMobile'
import PortfolioMobile from './MobileNavPages/PortfolioMobile'
import ContactMobile from './MobileNavPages/ContactMobile'
import AudioPlayerMobile from './AudioPlayerMobile'

const NavBarMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(null)
  const [showLogo, setShowLogo] = useState(true)
  const [showMenuBtn, setShowMenuBtn] = useState(true)
  const [showNavBar, setShowNavBar] = useState(true)

  const toggleLogo = () => {
    if (currentPage !== null) {
      setMenuOpen(false)
      setCurrentPage(null)
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleNavClick = path => {
    setMenuOpen(!menuOpen)

    setCurrentPage(path)
    console.log(path)
  }

  const socialLinkTo = url => {
    window.open(url)
  }

  useEffect(() => {
    console.log('menuOpen: ' + menuOpen)
    console.log('currentPage: ' + currentPage)
  }, [menuOpen, currentPage])

  return (
    <>
      <div className={`mobileUI ${menuOpen ? 'menu-open' : ''}`}>
        {menuOpen && <div className="menu-area-bg"></div>}
        {showNavBar && (
          <header className="nav-bar-mobile">
            {showLogo && (
              <div className="logo" onClick={toggleLogo}>
                <img
                  src={menuOpen ? logo_white : samoi}
                  alt="Samoi"
                  className="samoi-img"
                />
              </div>
            )}
            {showMenuBtn && (
              <div className="menu-btn" onClick={toggleMenu}>
                <img
                  src={menuOpen ? menu_close_mobile : menu_mobile}
                  alt=""
                  className="menu-icon"
                />
              </div>
            )}
          </header>
        )}

        {menuOpen && (
          <>
            <nav className="menu-area">
              <ul className="nav-list">
                {navLinksMobile.map(nav => (
                  <li
                    key={nav.id}
                    className="nav-item"
                    onClick={() => {
                      handleNavClick(nav.id)
                    }}
                  >
                    <a target="_blank">
                      <img src={deco_orange} alt="" className="deco-left" />
                      <p className="title">{nav.title} </p>
                      <p className="title-en"> {nav.titleEn}</p>
                      <img src={deco_orange} alt="" className="deco-right" />
                    </a>
                  </li>
                ))}
              </ul>
              {/* <img src={sound_mobile} alt="" className="sound-control" /> */}
              {/* <AudioPlayerMobile /> */}
            </nav>

            <div className="social-media-bar--mobile">
              <div
                className="ic facebook"
                onClick={() => {
                  socialLinkTo('https://www.facebook.com/samoitech/')
                }}
              >
                <img src={fb_mobile} alt="facebook" />
              </div>
              <div
                className="ic instagram"
                onClick={() => {
                  socialLinkTo('https://www.instagram.com/samoi0309/')
                }}
              >
                <img src={ins_mobile} alt="" />
              </div>
              <div
                className="ic twitter"
                onClick={() => {
                  socialLinkTo('https://twitter.com/SamoiTech')
                }}
              >
                <img src={twitter_mobile} alt="" />
              </div>
            </div>
          </>
        )}

        {currentPage !== null && !menuOpen && (
          <>
            {currentPage === 'about-us' && <AboutUsMobile />}
            {/* {currentPage === 'news' && <NewsMobile />} */}
            {currentPage === 'equipment' && <EquipmentMobile />}
            {currentPage === 'service' && <ServiceMobile />}
            {currentPage === 'portfolio' && (
              <PortfolioMobile
                setShowNavBar={setShowNavBar}
                setShowLogo={setShowLogo}
                setShowMenuBtn={setShowMenuBtn}
              />
            )}
            {currentPage === 'contact' && <ContactMobile />}
          </>
        )}
      </div>
    </>
  )
}

export default NavBarMobile
