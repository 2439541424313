import React from 'react'
import AccordionHeader from './AccordionHeader'
import AccordionContent from './AccordionContent'
import { AccordionType } from './Types'

interface AccordionItemProps extends AccordionType {
  onTitleClick: () => void
  isActive: boolean
  activeContentIndex: number
  setActiveContentIndex: (index: number) => void
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  onTitleClick,
  isActive,
  activeContentIndex,
  setActiveContentIndex
}) => {
  return (
    <div className="accordion-item">
      <AccordionHeader
        title={title}
        onTitleClick={onTitleClick}
        isActive={isActive}
      />
      <AccordionContent
        content={content}
        isActive={isActive}
        activeContentIndex={activeContentIndex}
        setActiveContentIndex={setActiveContentIndex}
      />
    </div>
  )
}

export default AccordionItem
