import { useState } from 'react'
import './equipment-mobile.scss'
import { equipment_mobile } from 'public/assets/index'
import data from '../NavPages/Equipment/Data'

const EquipmentMobile = () => {
  const [isDetail, setIsDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [videoEmbedUrl, setVideoEmbedUrl] = useState(null)

  const handleDetail = (item, i) => {
    setIsDetail(true)
    setSelectedItem(i)

    const getVideoEmbedUrl = url => {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w\d-]+)/
      const match = url.match(regex)
      return match ? `https://www.youtube.com/embed/${match[1]}` : null
    }

    if (item.isImg === false) {
      setVideoEmbedUrl(getVideoEmbedUrl(item.url))
      console.log(videoEmbedUrl)
    }
  }

  return (
    <div className="content-mobile">
      <img src={equipment_mobile} alt="" className="title" />
      <div className="list-area">
        <ul className="list">
          {data.map((item, i) => (
            <li
              className={`list-item ${'item-' + i} ${
                selectedItem === i ? 'selected' : ''
              }`}
              onClick={() => {
                handleDetail(item, i)
              }}
            >
              <p className="titleEn">{item.title}</p>
              <p className="titleCn">{item.subTitle}</p>

              {isDetail && selectedItem === i && (
                <div className="detail">
                  {item.isImg ? (
                    <img src={item.url} alt="" className="detail-img" />
                  ) : (
                    <iframe
                      width="342"
                      height="206"
                      src={videoEmbedUrl}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="video"
                    ></iframe>
                  )}

                  <div className="desc">{item.content}</div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default EquipmentMobile
