import './contact.scss'
import { contact_title } from 'public/assets'
import { CameraContext } from '../MainPage'
import { useContext } from 'react'

function Contact(props) {
  const { updateGlobalState } = useContext(CameraContext)
  function onBackClick() {
    // updateCameraContext(true)
    localStorage.setItem('Path', 'Back')
    // updateBackward(true)
    updateGlobalState({ isBackWard: true })
  }
  return (
    <div
      className="contact"
      style={{ display: props.display, visibility: props.visibility }}
    >
      <div className="card-container">
        <div className="title-container">
          <div className="dot left"></div>
          <img src={contact_title} alt="Contact" className="title-img" />
          <div className="dot right"></div>
        </div>
        <div className="info-container">
          <p className="sub-title">聯繫我們</p>
          <ul className="list">
            <li>
              地址
              <p>106台北市大安區敦化南路二段99號3樓</p>
            </li>
            <li>
              電話
              <p>(02) 2664-2500</p>
            </li>
            <li>
              時間
              <p>Mon－Fri 10AM－7PM</p>
            </li>
            <li>
              信箱
              <p>Services@samoi.com.tw</p>
            </li>
          </ul>
          <button className="mail-back" onClick={onBackClick}>
            返回
          </button>
        </div>
      </div>
    </div>
  )
}

export default Contact
