import React, { useState, useRef, useCallback } from 'react'

interface ImageGalleryProps {
  images: string[]
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [offset, setOffset] = useState(0)
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setDragStart(e.clientX)
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grabbing'
    }
  }

  const handleMouseUp = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab'
    }

    const threshold = 100
    if (offset > threshold) {
      setActiveIndex(prevIndex =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      )
    } else if (offset < -threshold) {
      setActiveIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      )
    }
    setOffset(0)
  }, [offset, images.length])

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (sliderRef.current && sliderRef.current.style.cursor === 'grabbing') {
      const movementX = e.clientX - dragStart
      setOffset(movementX)
    }
  }

  const handleMouseLeave = () => {
    handleMouseUp()
  }

  return (
    <div
      className="image-gallery"
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={images[activeIndex]}
        alt={`Slide ${activeIndex}`}
        className="image"
      />

      <div className="spots">
        {images.map((image, index) => (
          <div
            key={index}
            className={`spot ${index === activeIndex ? 'active' : ''}`}
            onClick={() => setActiveIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default ImageGallery
