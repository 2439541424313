import { equipment_bg } from 'public/assets/index'
import { useMemo } from 'react'

const MainList = ({ itemClickHandler }) => {
  const date = new Date()
  const year = useMemo(() => date.getFullYear())

  const equipmentList = [
    { name: 'holo studio', content: 'HOLO Studio(動態全息攝影棚)' },
    { name: 'T-Pose', content: 'T-Pose(靜態3D寫真建模系統)' },
    { name: 'Motion Capture', content: 'Motion Capture(動態捕捉系統棚)' },
    { name: 'Panasonic', content: 'Panasonic PT-RQ50K' },
    { name: 'Faro', content: 'Faro Focus Laser Scanner' },
    { name: 'Lightmem', content: 'Lightmem®光瞬膜' },
    { name: 'MR', content: 'MR(Mixed-Reality)眼鏡內容展示' },
    { name: 'Back', content: 'Go Back返回' },
  ]

  return (
    <>
      <div className="mainlist-content">
        <div className="nav">
          <div className="nav-content">
            SCORE
            <p>0000</p>
          </div>
          <div className="nav-content">
            HIGH SCORE
            <p>0000</p>
          </div>
          <div className="nav-content">
            LEVEL
            <p>0000</p>
          </div>
        </div>
        <div className="title">
          <img src="./assets/iframe_equipment_title.svg" alt="" />
        </div>
        <div className="item-list">
          <ul>
            <div style={{ width: '100%', height: '100%' }}>
              {equipmentList.map((item, index) => {
                return (
                  <li
                    key={item.name}
                    className="list-item"
                    onClick={() => itemClickHandler(index)}
                  >
                    <p>{item.content}</p>
                  </li>
                )
              })}
            </div>
          </ul>
        </div>
        <div className="footer">
          <p>© {year} SAMOI TECHNOLOGY CO., LTD.</p>
        </div>
      </div>
    </>
  )
}

export default MainList
