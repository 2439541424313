import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { samoi, deco_purple } from 'public/assets'
import './nav-bar.scss'
import { navLinks } from '../../Constants'
import { CameraContext } from './MainPage'

const NavBar = (fn, { navProps }) => {
  // const {
  //   isAbout,
  //   setIsAbout,
  //   isPortfolioCard,
  //   setIsPortfolioCard,
  //   isService,
  //   setIsService,
  //   isContact,
  //   setIsContact,
  // } = navProps

  const [slideIn, setSlideIn] = useState(false)
  const [isSliderMask, setIsSliderMask] = useState(false)
  const [disableNavSliderControl, setDisableNavSliderControl] = useState(false)
  const {globalState} = useContext(CameraContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSliderMask(true)
      setSlideIn(true)
      setTimeout(() => {
        setIsSliderMask(false)
      }, 700)
    }, 700)
    return () => clearTimeout(timer)
  }, [])

  useEffect(()=>{
    setSlideIn(globalState.navSlider)
    navSliderControl()
  },[globalState.navSlider])

  const navTo = val => {
    fn.refreshUI(false)
    fn.nav(val)
  }



  const navSliderControl = () => {
    if (slideIn === true) {
      setIsSliderMask(true)
      setSlideIn(false)
      setTimeout(() => {
        setIsSliderMask(false)
      }, 700)
    } else {
      setIsSliderMask(true)
      setSlideIn(true)
      setTimeout(() => {
        setIsSliderMask(false)
      }, 700)
    }
  }

  return (
    <>
      <Router>
        <div className="nav-bar">
          {isSliderMask && (
            <div className="slider-logo-mask">
              <div className="mask-spot"></div>
            </div>
          )}
          <div
            className="logo"
            onClick={() => !disableNavSliderControl && navSliderControl()}
          >
            <img src={samoi} alt="Samoi" />
          </div>
          <ul
            className={
              slideIn ? `nav-container slide-in` : `nav-container slide-out`
            }
          >
            {navLinks.map(nav => (
              <li
                key={nav.id}
                className="nav-item"
                onClick={() => navTo(`/${nav.id}`)}
              >
                <a target="_blank">
                  <img src={deco_purple} alt="" className="deco-left" />
                  {nav.title}
                  <img src={deco_purple} alt="" className="deco-right" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Router>
    </>
  )
}

export default NavBar
