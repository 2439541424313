const ServiceNav = ({ itemIndexHandler }) => {
  return (
    <>
    <div className="service-title">
      <img src="./assets/ServiceTitle.png" alt="" ></img>
    </div>
    <div className="service-nav">
      <button className="service-back-btn" onClick={() => itemIndexHandler(5)}>
        <img src="./assets/back_btn_circle.svg" alt=""></img>
        <p>返回</p>
      </button>
      <div className="divider"></div>
      <button className="service-nav-btn" onClick={() => itemIndexHandler(0)}>
        <p>客製化科技展演規劃</p>
      </button>
      <div className="divider"></div>
      <button className="service-nav-btn" onClick={() => itemIndexHandler(1)}>
        <p>設計/製作服務</p>
      </button>
      <div className="divider"></div>
      <button className="service-nav-btn" onClick={() => itemIndexHandler(2)}>
        <p>設備租貸</p>
      </button>
      <div className="divider"></div>
      <button className="service-nav-btn" onClick={() => itemIndexHandler(3)}>
        <p>廣告合作</p>
      </button>
      <div className="divider"></div>
      <button className="service-nav-btn" onClick={() => itemIndexHandler(4)}>
        <p>區塊鏈</p>
      </button>
      <div className="divider"></div>
    </div>
    </>
  )
}

export default ServiceNav
