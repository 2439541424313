import './contact-mobile.scss'
import { contact_mobile, deco_purple } from 'public/assets/index'

const ContactMobile = () => {
  return (
    <div className="content-mobile">
      <img src={contact_mobile} alt="" className="title" />
      <div className="info-area">
        <ul className="info-list">
          <li className="info-item">
            <div className="title">
              <img src={deco_purple} alt="" className="deco-left" />
              <p className="title-p">地址</p>
              <img src={deco_purple} alt="" className="deco-right" />
            </div>
            <p className="desc">106台北市大安區敦化南路二段99號3樓</p>
          </li>
          <li className="info-item">
            <div className="title">
              <img src={deco_purple} alt="" className="deco-left" />
              <p className="title-p">電話</p>
              <img src={deco_purple} alt="" className="deco-right" />
            </div>
            <p className="desc">(02) 2664-2500</p>
          </li>
          <li className="info-item">
            <div className="title">
              <img src={deco_purple} alt="" className="deco-left" />
              <p className="title-p">時間</p>
              <img src={deco_purple} alt="" className="deco-right" />
            </div>
            <p className="desc">Mon－Fri 10AM－7PM</p>
          </li>
          <li className="info-item">
            <div className="title">
              <img src={deco_purple} alt="" className="deco-left" />
              <p className="title-p">信箱</p>
              <img src={deco_purple} alt="" className="deco-right" />
            </div>
            <p className="desc">Services@samoi.com.tw</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ContactMobile
