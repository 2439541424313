import { OrbitControls } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as THREE from 'three'
import { CameraContext } from './Component/Main/MainPage'

const cameraTarget = {
  origin: new THREE.Vector3(-152.8, -6.48, -13.78),
  intro: {
    position: new THREE.Vector3(6.7, 9.84, 74.54),
    mobilePosition: new THREE.Vector3(6.7, 9.84, 250.54),
    rotation: new THREE.Vector3(-1.5, 0.04, 0.51),
    target: new THREE.Vector3(0, -180, 62.5),
    name: 'Intro',
  },
  contact: {
    position: new THREE.Vector3(108.43, -25.2, 74.98),
    mobilePosition: new THREE.Vector3(164.52, -2.5, 171.71),
    rotation: new THREE.Vector3(-0.0271, 0.4673, 0.0122),
    target: new THREE.Vector3(0, -25, -80),
    name: 'Contact',
  },
  portfolio: {
    position: new THREE.Vector3(63.03, -22.27, -11.05),
    mobilePosition: new THREE.Vector3(71.48, -21.59, -14.59),
    rotation: new THREE.Vector3(3.1, 1.04, -3.11),
    target: new THREE.Vector3(13, -22.27, 20.0),
    name: 'Portfolio',
  },
  service: {
    position: new THREE.Vector3(76.2, 9.0, -44.83),
    mobilePosition: new THREE.Vector3(165.05, 16.18, -91.03),
    rotation: new THREE.Vector3(-3.12, 1.04, 3.12),
    target: new THREE.Vector3(0, 9.0, 0),
    name: 'Service',
  },
  equipment: {
    position: new THREE.Vector3(-42.53, -26.43, 23.68),
    mobilePosition: new THREE.Vector3(-71.7, -23.37, 36.85),
    rotation: new THREE.Vector3(0.02, -1.04, 0.02),
    target: new THREE.Vector3(0, -25.8, 0),
    name: 'Equipment',
  },
  star: {
    position: new THREE.Vector3(-47.84, 62.21, 27.12),
    rotation: new THREE.Vector3(0, -1.05, 0),
    name: 'star',
  },
  about: {
    position: new THREE.Vector3(-67.15, 9.4, 38.9),
    mobilePosition: new THREE.Vector3(-118.05, 8.73, 69.08),
    rotation: new THREE.Vector3(0.06, -1.04, 0.05),
    target: new THREE.Vector3(0, 10, 0),
    name: 'AboutUs',
  },
  extra: {
    position: new THREE.Vector3(40.93, -3.26, 65.93),
    mobilePosition: new THREE.Vector3(81.89, -0.77, 139.07),
    rotation: new THREE.Vector3(0.0, 0.52, 0.0),
    target: new THREE.Vector3(1.72, -3.55, -1.58),
    name: 'Extra',
  },
  back: {
    position: new THREE.Vector3(),
    rotation: new THREE.Vector3(),
    target: new THREE.Vector3(0, 5, 0),
    name: 'back',
  },
}

let t = new THREE.Vector3(0, 0, 0)

export default function Controller({ isMobile, setEnableHTML }) {
  const global = useRef({
    isCameraMotion: false,
    pageState: 'page state',
    isBackWard: false,
    navSlider: false,
    modalContent: 'main',
  })

  if (isMobile) {
    document.addEventListener('touchmove', function (e) {
      e.preventDefault()
    })
  }

  const cameraTargetRef = useRef(cameraTarget)

  const getTarget = () => {
    const targetMap = {
      Contact: cameraTargetRef.current.contact,
      Service: cameraTargetRef.current.service,
      Portfolio: cameraTargetRef.current.portfolio,
      AboutUs: cameraTargetRef.current.about,
      Equipment: cameraTargetRef.current.equipment,
      Back: cameraTargetRef.current.back,
      Extra: cameraTargetRef.current.extra,
    }
    return targetMap[localStorage.getItem('Path')] || null
  }

  let state = {
    play: false,
    playBack: false,
    page: null,
    target: null,
    orbitControlEnabled: true,
    polarAngle: [1.0, 1.5],
    distance: isMobile ? [200, 600] : [200, 400],
  }

  let stateHandler = {
    IDLE: {
      play: false,
      playBack: false,
      page: null,
      target: null,
      orbitControlEnabled: true,
      polarAngle: [1.0, 1.5],
      distance: isMobile ? [200, 600] : [200, 400],
    },
    PLAY: {
      play: true,
      playBack: false,
      target: getTarget(),
      orbitControlEnabled: false,
      polarAngle: [0, 3.14],
      distance: [0, 1000],
    },
    BACK: {
      play: true,
      playBack: true,
      target: getTarget(),
      orbitControlEnabled: false,
      polarAngle: [0, 3.14],
      distance: [0, 1000],
    },
    UPDATE: {
      play: true,
      target: getTarget(),
    },
    HOLD: {
      play: false,
    },
  }

  const [controller, setController] = useState({ ...state })

  const { globalState, updateGlobalState } = useContext(CameraContext)

  const delta = useRef(0)
  const orbitControlRef = useRef()
  const backKey = false

  function getPos() {
    let t
    if (orbitControlRef.current != undefined) {
      const x = orbitControlRef.current.object.rotation.x
      const y = orbitControlRef.current.object.rotation.y
      const z = orbitControlRef.current.object.rotation.z
      const vec = new THREE.Vector3(x, y, z)
      t = {
        position: orbitControlRef.current.object.position.clone(),
        rotation: vec,
        target: new THREE.Vector3(0, 5, 0),
        name: 'back',
      }
    }
    return t
  }

  function ExitCameraMotion(type) {
    if (type === 'exit') {
      setController(controller => ({
        ...controller,
        ...stateHandler.IDLE,
      }))
      global.current.isCameraMotion = false
    } else {
      setController(controller => ({
        ...controller,
        ...stateHandler.HOLD,
      }))
    }
    global.current.navSlider = false
  }

  useEffect(() => {
    if (globalState.isCameraMotion) {
      setEnableHTML('none')
      if (backKey || globalState.isBackWard) {
        setController(controller => ({
          ...controller,
          ...stateHandler.BACK,
        }))
        global.current.modalContent = 'hide'
        global.current.pageState = 'back'
        global.current.navSlider = true
        global.current.isBackWard = false
      }
    } else {
      global.current.isBackWard = false
    }
  }, [backKey, globalState.isBackWard])

  useEffect(() => {
    if (globalState.isCameraMotion) {
      setController(controller => ({
        ...controller,
        ...stateHandler.PLAY,
      }))
      global.current.isCameraMotion = true
      global.current.navSlider = true
      cameraTarget.back = getPos()
    } else {
      setController(controller => ({
        ...controller,
        ...stateHandler.IDLE,
      }))
    }
  }, [globalState.isCameraMotion])

  useEffect(() => {
    if (globalState.isCameraMotion) {
      setController(controller => ({
        ...controller,
        ...stateHandler.UPDATE,
      }))
      global.current.modalContent = 'null'
      global.current.navSlider = true
    } else {
      setController(controller => ({
        ...controller,
        ...stateHandler.IDLE,
      }))
    }
  }, [globalState.pageState])

  const dis = useRef()

  // useEffect(()=>{
  //   console.log(controller)
  // },[controller])

  useFrame(state => {
    // console.log(state.camera.position)
    if (globalState.isCameraMotion) {
      if (isMobile) {
        global.current.isCameraMotion = false
      } else {
      if (controller.play) {
        global.current.idle = false
        dis.current = state.camera.position.distanceTo(
          controller.target.position
        )
        if (dis.current < 1) {
          global.current.pageState = controller.target.name
        }
        if (dis.current < 0.1 && controller.playBack) {
          delta.current = 0
          ExitCameraMotion('exit')
        } else if (dis.current < 0.1) {
          delta.current = 0
          if (
            globalState.pageState === 'Portfolio' ||
            (globalState.pageState === 'AboutUs' &&
              global.current.modalContent != 'null')
          ) {
            global.current.modalContent = 'null'
          } else {
            global.current.modalContent = globalState.pageState
          }
          setEnableHTML('auto')
          ExitCameraMotion('focus')
          global.current.idle = true
        } else {
          delta.current += 0.003
          if (isMobile) {
            orbitControlRef.current.object.position.lerp(
              controller.target.mobilePosition,
              delta.current
            )
          } else {
            orbitControlRef.current.object.position.lerp(
              controller.target.position,
              delta.current
            )
          }
          orbitControlRef.current.target.lerp(
            controller.target.target,
            delta.current
          )
          orbitControlRef.current.update()
        }
        }
      }
    } else {
      orbitControlRef.current.target = cameraTargetRef.current.back.target
    }
  })

  const { idle, isCameraMotion, pageState, isBackWard, navSlider, modalContent } =
    global.current

  useEffect(() => {
    updateGlobalState(global.current)
  }, [idle, isCameraMotion, pageState, isBackWard, navSlider, modalContent])

  return (
    <>
      {
        <OrbitControls
          ref={orbitControlRef}
          makeDefault
          enabled={controller.orbitControlEnabled}
          enableDamping
          dampingFactor={0.1}
          minPolarAngle={controller.polarAngle[0]}
          maxPolarAngle={controller.polarAngle[1]}
          minDistance={controller.distance[0]}
          maxDistance={controller.distance[1]}
          enablePan={false}
        />
      }
    </>
  )
}
