const sparksFragmentShader = /* glsl */ `
uniform float uTime;
varying float vDistance;


void main() {
  // vec3 color = vec3(0.34, 0.53, 0.96);
  vec3 color = vec3(0.5, 0.88, 0.988);
  float strength = distance(gl_PointCoord, vec2(0.5));
  strength = 1.0 - strength;
  strength = pow(strength, 3.0);

  // color = mix(color, vec3(0.37, 0.70, 0.95), vDistance * 0.5);
  // color = mix(color, vec3(0.97, 0.70, 0.45), vDistance * 0.5);
  // color = mix(vec3(0.0), color, strength);
  if(strength < 0.5) discard;
  gl_FragColor = vec4(color, strength);
}
`

export default sparksFragmentShader
