import { useState } from 'react'
import './portfolio-mobile.scss'
import { portfolio_mobile, back_mobile } from 'public/assets/index'
import data from './portfolioData'

const PortfolioMobile = ({ setShowNavBar, setShowLogo, setShowMenuBtn }) => {
  const [isDetail, setIsDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItemContent, setSelectedItemContent] = useState(null)
  const [eachContent, setEachContent] = useState(null)
  const [videoEmbedUrl, setVideoEmbedUrl] = useState(null)

  const handleDetail = (item, i) => {
    setIsDetail(true)
    setSelectedItem(i)
    setSelectedItemContent(item.content)
  }

  const handleContent = (c, j) => {
    setEachContent(c)
    setShowLogo(false)
    setShowMenuBtn(false)
    setShowNavBar(false)

    const getVideoEmbedUrl = url => {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w\d-]+)/
      const match = url.match(regex)
      return match ? `https://www.youtube.com/embed/${match[1]}` : null
    }

    if (c.video !== '') {
      setVideoEmbedUrl(getVideoEmbedUrl(c.video))
      console.log(videoEmbedUrl)
    }
  }

  const backToList = () => {
    setSelectedItem(null)
    setSelectedItemContent(null)
    setEachContent(null)
    setVideoEmbedUrl(null)
    setShowLogo(true)
    setShowMenuBtn(true)
    setShowNavBar(true)
  }

  return (
    <div className="content-mobile">
      {eachContent && (
        <img
          src={back_mobile}
          alt=""
          className="back-btn"
          onClick={backToList}
        />
      )}
      <img src={portfolio_mobile} alt="" className="title" />
      <div className="list-area">
        {eachContent === null && (
          <ul className="list">
            {data.map((item, i) => (
              <li
                key={item.id}
                className={`list-item ${'item-' + i} ${
                  selectedItem === i ? 'selected' : ''
                }`}
                onClick={() => {
                  handleDetail(item, i)
                }}
              >
                <p className="titleEn">{item.titleEn}</p>
                <p className="titleCn">{item.title}</p>
                {isDetail && selectedItem === i && (
                  <div className="detail">
                    {selectedItemContent &&
                      selectedItemContent.map((c, j) => (
                        <>
                          <div
                            key={c.id}
                            className="content-title"
                            onClick={() => {
                              handleContent(c, j)
                            }}
                          >
                            {c.subTitle}
                          </div>
                        </>
                      ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        {eachContent && (
          <div className="each-content">
            <div className="sub-title">{eachContent.subTitle}</div>
            {eachContent.image !== '' && (
              <img src={eachContent.image} alt="" className="image" />
            )}
            {eachContent.video !== '' && (
              <iframe
                width="342"
                height="206"
                src={videoEmbedUrl}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video"
              ></iframe>
            )}

            <p className="desc">{eachContent.description}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default PortfolioMobile
