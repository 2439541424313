import { OrbitControls } from "@react-three/drei"
import { extend, useThree } from "@react-three/fiber"
import { useEffect, useRef } from "react"

extend({OrbitControls})

const MobileController=()=>{
    const { camera } = useThree()
    const controller = useRef()
    useEffect(()=>{
        camera.position.set(105.5 * -3, -50, 105.5 * -3)
    },[])

    return(
        <OrbitControls 
        ref={controller}
        makeDefault
        enableZoom
        enableDamping
        dampingFactor={0.1}
        minPolarAngle={1.0}
        maxPolarAngle={1.5}
        minDistance={200}
        maxDistance={500}
        enablePan={false}
        />
    )
}

export default MobileController