import React from 'react'
import { plus_icon } from '../../../../../public/assets/'

interface AccordionHeaderProps {
  title: string
  onTitleClick: () => void
  isActive: boolean
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  title,
  onTitleClick,
  isActive,
}) => {
  const activeClass = isActive ? 'active' : ''

  return (
    <div className="title-container" onClick={onTitleClick}>
      <div className={`title ${activeClass}`}>{title}</div>
      <img src={plus_icon} alt="" className="plus-icon" />
    </div>
  )
}

export default AccordionHeader
