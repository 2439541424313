import {
  equipment_img_0,
  equipment_img_1,
  equipment_img_3,
  equipment_img_4,
  equipment_img_5,
} from 'public/assets'

const data = [
  {
    title: 'HOLO Studio',
    subTitle: '（動態全息攝影棚）',
    isImg:true,
    url: equipment_img_0,
    content:
      '獨家攝影棚，擁有業界最強規格，唯一4K、64顆高畫質鏡頭，屏棄傳統手工曠日費時的建模技術，只要進入攝影棚，即可在幾秒內完成細緻的動態影像掃描，零死角捕捉每一個細節，打造出身歷其境的全自由視角影片。可利用類似於非物質文化遺產保留與各種娛樂產業。',
  },
  {
    title: 'T-Pose',
    subTitle: '（靜態3D寫真建模系統）',
    isImg:true,
    url: equipment_img_1,
    content:
      '有別於動態棚，T-Pose攝影棚提供靜態3D寫真建模掃描服務，用於物質文化的保存，如：古文物、寫真、寵物等等…以固定形貌產出數位模型，並可進一步製作成為實體模型產品。',
  },
  {
    title: 'Motion Capture',
    subTitle: '（動態捕捉系統棚）',
    isImg:false,
    url: 'https://www.youtube.com/embed/XDz8J3pq9fM',
    content:
      '透過真人演出動作，讓虛擬偶像實時動作，全身動態捕捉系統可精細的辨識各個人體部位，如手指動作，並可透過面部辨識系統捕捉演員表情，演繹在虛擬偶像，使其呈現猶如真人一般的生動活潑。',
  },
  {
    title: 'Panasonic PT-RQ50K',
    subTitle: '（五萬流明投影機）',
    isImg:true,
    url: equipment_img_3,
    content:
      'Panasonic PT-RQ50K是東京奧運指定同款高階工程用投影機種，精密的儀器與造價不菲的極致工藝技術，造就了稀有度，讓每一場投影表演都以最完美的方式呈現！',
  },
  {
    title: 'Faro Focus Laser Scanner',
    subTitle: '',
    isImg:true,
    url: equipment_img_4,
    content:
      '3D雷射掃描儀能精密計算出建築物結構之間的景深與距離，為投影拼接藝術做足最好的前置準備，是場地勘查的一大秘密武器。',
  },
  {
    title: 'Lightmem®',
    subTitle: '光瞬膜',
    isImg:true,
    url: equipment_img_5,
    content:
      'Lightmem®光瞬膜為專利投影介質結構，相較傳統投影布幕能有效提升亮度與彩度；獨家鎖光技術能抵抗外在的光害，常規版為100吋、120吋、150吋，也可客製化尺寸，搭配投影機，廣泛使用於家庭劇院。',
  },
  {
    title: 'MR(Mixed-Reality',
    subTitle: '眼鏡內容展示',
    isImg:false,
    url: 'https://www.youtube.com/embed/bsQlYMgMHhA',
    content:
      '沙漠魚不斷地在進行MR眼鏡的開發與運用，此人體解剖展示可運用於生物科技教學場域，在未來Sandpock與Quote平台數位內容將放置於所研發的MR眼鏡中，以期運用便利及普及化。',
  },
]

export default data
