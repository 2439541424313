import portfolio_taichung_opera from 'public/assets/portfolio_taichung_opera.png'
import portfolio_goodbye_van_gogh from 'public/assets/portfolio_goodbye_van_gogh.png'
import portfolio_impression_monet from 'public/assets/portfolio_impression_monet.png'
import portfolio_nft_1 from 'public/assets/portfolio_nft_1.png'
import portfolio_nft_2 from 'public/assets/portfolio_nft_2.png'
import portfolio_nft_3 from 'public/assets/portfolio_nft_3.png'

const data = [
  {
    title: '投影與光雕',
    titleEn: 'PROJECTION ',
    content: [
      {
        subTitle: '曲面裸視3D－士林萌虎',
        description:
          '由沙漠魚科技策劃及執行，於2022年首次在世界亮相的「曲面裸視3D牆」，座落於士林夜市大東路與大南路交界口，所執行範圍包含：硬體設計、安裝；以及製作精彩豐富的高難度動畫內容，「猛虎」與「萌虎」有奇趣的反差萌，深深擄獲民眾的心，並於發佈日－白晝之夜活動中創造2200萬次線上觀看高曝光量。',
        image: '',
        video: 'https://www.youtube.com/watch?v=GqjxAVutWPM',
      },
      {
        subTitle: '全息投影－傳說對決',
        description:
          '來自Gerena傳說對決舉辦的夏季冠軍賽，運用全息（浮空）投影所建置的大型虛實結合舞台，讓遊戲中的虛擬角色與真實偶像團體同台互動飆歌，在疫情期間依舊超人氣！',
        image: '',
        video: 'https://www.youtube.com/watch?v=RTZLIX4vQ1A',
      },
      {
        subTitle: '光雕投影－圓山大飯店',
        description:
          '沙漠魚科技於2022年12月所舉行的台灣再啟第三站《龍躍台北迎聖誕》光雕表演，出動了東京奧運指定同款高規格投影機：Panasonic RQ50K，高達五萬流明的亮度，搭配十餘台工程用35K機種，用科技藝術點亮充滿歷史之美的圓山大飯店。動畫內容表演分為四大主軸：平安天燈、結構之美、龍騰虎躍、國寶沙畫祈福，為人心惶惶的疫情期間，帶來光與溫暖的美好。',
        image: '',
        video: 'https://www.youtube.com/watch?v=pB3uKh4ymd8',
      },
      {
        subTitle: '光雕投影－彩虹大遊行',
        description:
          '2021年10月，於台北市政府前廣場舉辦的彩虹光雕秀－同志大遊行，現場團隊不畏寒冷的氣候，在高處努力不懈地進行投影拼接、測試，做好萬全的準備，只為迎來夜間精彩的大秀！在性別多元包容文化的氛圍下，共同見證了跨越隔閡的時代。',
        image: '',
        video: 'https://www.youtube.com/watch?v=MmRii1iEBPc',
      },
      {
        subTitle: '整體營造－桃園慈湖水岸光影季',
        description:
          '從2022年3月到6月，為期三個月，由沙漠魚科技策劃整體光環境營造、元宇宙互動光雕傳送門及安排藝人演唱、火舞等表演陣容、熱鬧市集；由豪器飛帆科技執導硬體相關設備，在開幕日更出動了東京奧運指定高規格同款投影機種，在山壁打出烏克蘭國旗，為世界和平祈福，一系列精彩的演出在三天創造八萬人次到訪。',
        image: '',
        video: 'https://www.youtube.com/watch?v=lhuUJXuYsq0',
      },
      {
        subTitle: '沈浸式投影－故宮龍藏經',
        description:
          '於2019年為國立台北故宮博物院歷史之名古文物《龍藏經》所執行的「浮影幻化．沈浸式互動劇場」，相傳見到龍藏經可以獲得七生七世的福氣，運用互動裝置藝術使之重現在世人眼前，榮獲休士頓影展金獎及多媒體傳承藝術獎國際殊榮，團隊執行投影及硬體互動工程。',
        image: '',
        video: 'https://www.youtube.com/watch?v=ffgs8EPm6Bo',
      },
      {
        subTitle: '沈浸式投影－台中歌劇院',
        description:
          '2021年於台中國家歌劇院所進行的《T.A.P光之曲幕》沈浸式投影，此由日本建築巨擘所設計的高打19米不規則曲面牆－美聲涵洞，團隊為國際級高難度的投影拼接工程做了完美的詮釋。',
        image: portfolio_taichung_opera,
        video: '',
      },
    ],
  },
  {
    title: '策展',
    titleEn: 'EXHIBITION ',
    content: [
      {
        subTitle: '再見．梵谷－光影體驗展',
        description:
          '首展至今十餘年，巡迴超過70個城市，《再見梵谷-光影體驗展》因應場地環境不斷蛻變，讓這個展覽宛如有機體般，持續更新、進化，每每呈現大異其趣而各自精彩的面貌。繼2022年台北站與高雄站之後，睽違兩年來到文化之都台南，製作團隊再度大膽突破展覽過去規格，巡迴十多年來首度加入黑色鏡面視覺效果，將三十多座巨幕環繞的視覺饗宴向下延伸，讓觀眾一腳踩進展區瞬間，便宛如陷入梵谷瑰麗迷幻的異境，將沉沈浸式體驗再度升級，顛覆台北站、高雄站的體驗，帶給觀眾截然不同的感官震撼，團隊參與執行投影工程建置。',
        image: portfolio_goodbye_van_gogh,
        video: '',
      },
      {
        subTitle: '印象．莫內－光影體驗展',
        description:
          '風靡30萬人，以現代科技演繹古典印象派對「光」與「色彩」的極致追求與體驗，不只於超過五公尺的巨幕重現，亦讓名家筆下揮灑的瑰麗油彩與細節筆觸，前所未有地躍然眼前，沉沈浸式投影區揉合影像、聲音、氣味，營造令觀眾全然投入的沈浸式觀展氛圍，約40部投影機、高達五公尺的多頻幕投影、超過2000多幅影像結構40分鐘感官饗宴，將印象派發展分為三個階段，搭配著古典配樂，完全融入印象派光影與色彩的流動之間，團隊參與執行投影工程建置。',
        image: portfolio_impression_monet,
        video: '',
      },
    ],
  },
  {
    title: '元宇宙',
    titleEn: 'METAVERSE ',
    content: [
      {
        subTitle: '沙帕卡－未來化顯示虛擬資產',
        description: '',
        image: '',
        video: 'https://www.youtube.com/watch?v=wftyEhqJ4UA&t=1s',
      },
      {
        subTitle: '測試平台畫面展示',
        description: '',
        image: '',
        video: 'https://www.youtube.com/watch?v=0Milt8R_4gU',
      },
      {
        subTitle: 'NFT',
        description: '',
        image: [portfolio_nft_1, portfolio_nft_2, portfolio_nft_3],
        video: '',
      },
      {
        subTitle: 'SMR（Seamless MR）虛實時空門',
        description: '',
        image: '',
        video: 'https://www.youtube.com/watch?v=kOOU0InruwQ',
      },
    ],
  },
]

export default data
