import React from 'react'
import { AccordionType } from './Types'

interface AccordionContentProps {
  content: AccordionType['content']
  isActive: boolean
  activeContentIndex: number
  setActiveContentIndex: (index: number) => void
}

const AccordionContent: React.FC<AccordionContentProps> = ({
  content,
  isActive,
  activeContentIndex,
  setActiveContentIndex,
}) => {
  const activeClass = isActive ? 'active' : ''

  const onSubTitleClick = (index: number) => {
    setActiveContentIndex(index)
  }

  return (
    <div className={`content ${activeClass}`}>
      <ul>
        {content.map((item, index) => (
          <li
            key={index}
            onClick={() => onSubTitleClick(index)}
            className={`sub-title ${
              index === activeContentIndex ? 'first-sub-title' : ''
            }`}
          >
            {item.subTitle}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccordionContent
