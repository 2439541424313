import React from 'react'
import { SubContentType } from './Types'
import ImageGallery from './ImageGallery'

interface SubLightBoxItemProps extends SubContentType {
  isActive: boolean
}

const SubLightBoxItem: React.FC<SubLightBoxItemProps> = ({
  description,
  image,
  video,
}) => {
  const getVideoEmbedUrl = (url: string) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w\d-]+)/
    const match = url.match(regex)
    return match ? `https://www.youtube.com/embed/${match[1]}` : null
  }

  const videoEmbedUrl = video ? getVideoEmbedUrl(video) : null

  const renderImage = () => {
    if (Array.isArray(image)) {
      return <ImageGallery images={image} />
    } else {
      return image && <img src={image} alt="" className="image" />
    }
  }

  return (
    <>
      <div className={`ui styled lightbox-item`}>
        <div className={`content`}>
          {renderImage()}
          {videoEmbedUrl && (
            <iframe
              width="560"
              height="315"
              src={videoEmbedUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video"
            ></iframe>
          )}
          <p className="description">{description}</p>
        </div>
      </div>
    </>
  )
}

export default SubLightBoxItem
