import React from 'react'
import SubLightBoxItem from './SubLightBoxItem'
import { AccordionType } from '../Accordion/Types'

interface SubLightBoxProps {
  items: AccordionType[]
  activeIndex: number
  activeContentIndex: number
}

const SubLightBox: React.FC<SubLightBoxProps> = ({
  items,
  activeIndex,
  activeContentIndex,
}) => {
  return (
    <div className="ui styled lightbox">
      {items.map((item, index) =>
        index === activeIndex
          ? item.content.map((listItem, _index) =>
              _index === activeContentIndex ? (
                <SubLightBoxItem
                  key={`${index}-${_index}`}
                  subTitle={listItem.subTitle}
                  description={listItem.description}
                  image={listItem.image}
                  video={listItem.video}
                  isActive={true}
                />
              ) : null
            )
          : null
      )}
    </div>
  )
}

export default SubLightBox
