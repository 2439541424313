import { Environment, PivotControls, useHelper } from '@react-three/drei'
import './light.css'
import { angleToRadians } from '../../Utility/angle'
import { useControls } from 'leva'
import { DirectionalLightHelper } from 'three'
import { useRef } from 'react'
import { RectAreaLightHelper } from 'three-stdlib'

export default function Light() {
  const directionalCtl = useControls('Directional Light', {
    visible: true,
    position: {
      x: 3.0,
      y: 5.0,
      z: -2.9,
    },
    castShadow: true,
  })

  const dirLightRef = useRef()
  useHelper(dirLightRef, DirectionalLightHelper)
  const rectLightRef = useRef()
  useHelper(rectLightRef, RectAreaLightHelper)
  return (
    <>
      {/* {console.log('render light')} */}
      <ambientLight args={['#404040', 0.5]} />
      <directionalLight
        ref={dirLightRef}
        visible={directionalCtl.visible}
        position={[
          directionalCtl.position.x,
          directionalCtl.position.y,
          directionalCtl.position.z,
        ]}
        intensity={5}
        color={'#CFD6F2'}
        castShadow={directionalCtl.castShadow}
        shadow-camera-left={-150}
        shadow-camera-top={150}
        shadow-camera-right={150}
        shadow-camera-bottom={-150}
      />
    </>
  )
}
