import './social-media-links.scss'
import { facebook, instagram, twitter } from 'public/assets'

const SocialMediaLinks = () => {
  const linkTo = url => {
    window.open(url)
  }

  return (
    <div className="social-media-bar">
      <div
        className="facebook"
        onClick={() => {
          linkTo('https://www.facebook.com/samoitech/')
        }}
      >
        <img src={facebook} alt="facebook" />
      </div>
      <div
        className="instagram"
        onClick={() => {
          linkTo('https://www.instagram.com/samoi0309/')
        }}
      >
        <img src={instagram} alt="" />
      </div>
      <div
        className="twitter"
        onClick={() => {
          linkTo('https://twitter.com/SamoiTech')
        }}
      >
        <img src={twitter} alt="" />
      </div>
    </div>
  )
}

export default SocialMediaLinks
