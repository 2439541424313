import 'normalize.css'
import ReactDOM from 'react-dom/client'
import React, { Suspense } from 'react'
import Loading from './Component/Main/Loading'
import App from './App'
import MainUI from './Component/Main/MainUI'
import ThreeCanvas from './Component/ThreeCanvas'
import MainPage from './Component/Main/MainPage'

const root = ReactDOM.createRoot(document.querySelector('#root'))
const style = {
  width: '100%',
  height: '100vh',
}

root.render(
  <>
    <MainPage />
  </>
)
