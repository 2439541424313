import { shaderMaterial } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useMemo, useRef } from 'react'
import sparksFragmentShader from '../../Shader/sparksFragmentShader'
import sparksVertexShader from '../../Shader/sparksVertexShader'
import * as THREE from 'three'

const Stars = props => {
  const { count } = props
  const radius = 2
  const points = useRef()
  const roundPoints = useRef()

  const particlesPosition = useMemo(() => {
    const positions = new Float32Array(count * 3)

    for (let i = 0; i < count; i++) {
      const distance = Math.sqrt(Math.random()) * radius * 0.95
      const theta = THREE.MathUtils.randFloatSpread(360)
      const phi = THREE.MathUtils.randFloatSpread(360)

      let x = distance * Math.sin(theta) * Math.cos(phi)
      let y = distance * Math.sin(theta) * Math.sin(phi)
      let z = distance * Math.cos(theta)
      positions.set([x, y, z], i * 3)
    }
    return positions
  }, [count])

  const uniforms = useMemo(
    () => ({
      uTime: {
        value: 0.0,
      },
      uRadius: {
        value: radius,
      },
    }),
    []
  )

  const roundParticlePositions = useMemo(() => {
    const positions = new Float32Array(count * 3)

    for (let i = 0; i < count; i++) {
      const distance = 2 + Math.sqrt(Math.random() + 3) * radius
      const theta = THREE.MathUtils.randFloatSpread(360)
      const phi = THREE.MathUtils.randFloatSpread(360)
      const dx = Math.sin(theta)
      const tx = Math.cos(phi)
      const dy = Math.cos(theta)
      const r = radius * 1.5 + Math.random() * 2
      const maxR = radius * 1.5 + 2
      const x = r * dx
      const z = r * dy
      const factor =
        radius * 1.5 + 2 - Math.sqrt(Math.pow(x, 2) + Math.pow(z, 2))
      const a = Math.sin(Math.random() * (Math.PI * 2)) * 0.15
      const y = a * factor
      positions.set([x, y, z], i * 3)
    }
    return positions
  }, [count])

  const xvalue = 0.001
  const yvalue = 0.0001
  useFrame(state => {
    const { clock } = state
    const t = clock.elapsedTime
    points.current.material.uniforms['uTime'].value = t
    roundPoints.current.material.uniforms['uTime'].value = t
    const d = Math.sin(t * 0.3 * 2.5 + 100) + 70
    points.current.position.set(0, d, 0)
    roundPoints.current.position.set(0, d, 0)
  })

  return (
    <>
      <points ref={points} rotation={[0.15, 0, 0.13]} scale={10}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          ></bufferAttribute>
        </bufferGeometry>
        <shaderMaterial
          blending={THREE.AdditiveBlending}
          depthWrite={true}
          fragmentShader={sparksFragmentShader}
          vertexShader={sparksVertexShader}
          uniforms={uniforms}
        ></shaderMaterial>
      </points>
      <points ref={roundPoints} scale={10} userData={[{light:'true'}]}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={roundParticlePositions.length / 3}
            array={roundParticlePositions}
            itemSize={3}
          ></bufferAttribute>
        </bufferGeometry>
        <shaderMaterial
          blending={THREE.AdditiveBlending}
          depthWrite={true}
          fragmentShader={sparksFragmentShader}
          vertexShader={sparksVertexShader}
          uniforms={uniforms}
        ></shaderMaterial>
      </points>
    </>
  )
}

export default Stars
