import React from 'react'
import AccordionItem from './AccordionItem'
import { AccordionType } from './Types'
import './accordion.scss'

interface AccordionProps {
  items: AccordionType[]
  activeIndex: number
  activeContentIndex: number
  setActiveIndex: (index: number) => void
  setActiveContentIndex: (index: number) => void
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  activeIndex,
  activeContentIndex,
  setActiveIndex,
  setActiveContentIndex,
}) => {
  const onTitleClick = (index: number) => {
    setActiveIndex(index)
    setActiveContentIndex(0)
  }

  const renderedItems = items.map(({ title, content }, index) => {
    return (
      <AccordionItem
        key={index}
        title={title}
        content={content}
        onTitleClick={() => onTitleClick(index)}
        isActive={activeIndex === index}
        activeContentIndex={activeContentIndex}
        setActiveContentIndex={setActiveContentIndex}
      />
    )
  })

  return <div className="ui styled accordion">{renderedItems}</div>
}

export default Accordion
