import { Perf } from 'r3f-perf'
import Light from './Browser/Light'
import PostProcessing from './Browser/PostProcessing'
import Stars from './Browser/Stars'
import House from './Browser/House'
import { isBrowser, isMobile } from 'react-device-detect'
import React from 'react'
import { Leva, useControls } from 'leva'

const Sandpock = React.memo(({ navProps, device, html }) => {
  if (isMobile) {
    return (
      <>
        <Light />
        <House navProps={navProps} isMobile={true} />
        <PostProcessing />
        <Stars count={4000} />
      </>
    )
  }

  if (isBrowser) {
    return (
      <>
        <Light />
        <House navProps={navProps} isMobile={false} html={html} />
        <Stars count={5000} />
        <PostProcessing device={device} />
        <Perf position="top-right" />
      </>
    )
  }
})

export default Sandpock
