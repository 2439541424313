import {
  Bloom,
  EffectComposer,
  BrightnessContrast,
  Noise,
  Vignette,
} from '@react-three/postprocessing'
import { useControls } from 'leva'
import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'

export default function PostProcessing({ device }) {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  const props = useMemo(() => {
    let os;
    if (userAgent.indexOf('Win') != -1) os = 'Windows'
    else if (userAgent.indexOf('Mac') != -1) os = 'Mac'
    else if (userAgent.indexOf('X11') != -1) os = 'UNIX'
    else if (userAgent.indexOf('Linux') != -1) os = 'Linux'
    console.log(os)
    if (os === 'Windows' || os === 'Linux' || os === 'UNIX') {
      return [0.15, 0.35, 0.7]
    } else if (os === 'Mac') {
      return [0.1, 0.3, 2.0]
    } else if (/windows phone/i.test(userAgent)) {
      return [0.15, 0.35, 0.7]
    } else if (/android/i.test(userAgent)) {
      return [0.15, 0.35, 0.7]
    } else if (/ipad|iphone/.test(userAgent) && !window.MSStream) {
      return [0.1, 0.3, 2.0]
    } else {
      return [0.1, 0.3, 2.0]
    }
  })

  const brightness = useControls('Brightness', {
    value: {
      value: 0.15,
      min: 0,
      max: 1,
      step: 0.01,
    },
  })
  const contrast = useControls('Contrast', {
    value: {
      value: 0.35,
      min: 0,
      max: 1,
      step: 0.01,
    },
  })
  const intensity = useControls('Intensity', {
    value: {
      value: 0.7,
      min: 0,
      max: 1,
      step: 0.01,
    },
  })
  const enableBrightnessContrast = useControls('enabledBrightnessContrast', {
    value: {
      value: true,
    },
  })
  const enableBloom = useControls('enabledbloom', {
    value: {
      value: true,
    },
  })
  //brightness: 0.1, constrast: 0.3

  return (
    <>
      <EffectComposer stencilBuffer={true}>
        {enableBrightnessContrast.value && (
          <BrightnessContrast brightness={props[0]} contrast={props[1]} />
        )}
        {enableBloom.value && (
          <Bloom
            mipmapBlur
            radius={0.8}
            levels={10}
            intensity={props[2]}
            luminanceThreshold={0.2}
            luminanceSmoothing={0.01}
            blendFunction={18}
            width={32}
            height={32}
          />
        )}
        <Noise opacity={0.025} />
        <Vignette eskil={true} offset={0.1} darkness={1.1} />
      </EffectComposer>
    </>
  )
}
