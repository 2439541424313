import MainList from './MainList'
import { useContext, useEffect, useState } from 'react'
import './scss/equipment.scss'
import EquipmentContent from './EquipmentContent'
import { CameraContext } from '../../MainPage'

const Equipment = props => {
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const { updateGlobalState } = useContext(CameraContext)

  const handleItemClick = index => {
    if (index === 7) {
      localStorage.setItem('Path', 'Back')
      updateGlobalState({isBackWard:true})
    } else {
      setSelectedEquipment(index)
    }
  }

  return (
    <div className="equipment-component">
      {selectedEquipment === null ? (
        <MainList itemClickHandler={handleItemClick} />
      ) : (
        <EquipmentContent
          selectedEquipment={selectedEquipment}
          setSelectedEquipment={setSelectedEquipment}
        />
      )}
    </div>
  )
}

export default Equipment
