import {
  service_bg_0,
  service_bg_1,
  service_bg_2,
  service_bg_3,
  service_bg_4,
  service_menu_0,
  service_menu_1,
  service_menu_2,
  service_menu_3,
  service_menu_4,
  service_mobile_01,
  service_mobile_02,
  service_mobile_03,
  service_mobile_04,
  service_mobile_05,
} from 'public/assets'

const data = [
  {
    title: service_menu_0,
    img: service_bg_0,
    content: ['光雕投影、沈浸式投影、全息投影、技術整合規劃'],
    titleText: '客製化科技展演規劃',
    imgMobile: service_mobile_01,
  },
  {
    title: service_menu_1,
    img: service_bg_1,
    content: [
      '網頁 / APP － UI/UX設計、網站後台架設、WEB2.0 & WEB3.0 建置、IOS & Android APP 開發、Discord 平台功能客製化',
      '2D － 平面設計、CIS設計、角色設計、場景設計',
      '3D － 高/低模建模/ 貼圖繪製 / 材質設定、 骨架與拳重設定、動作製作',
      'Unity － AR、VR、MR、XR 技術開發、臉部控制器',
    ],
    titleText: '設計/製作服務',
    imgMobile: service_mobile_02,
  },
  {
    title: service_menu_2,
    img: service_bg_2,
    content: [
      '攝影棚 － Holo Studio全息攝影棚、A-Pose 3D寫真建模系統、Motion Capture動態捕捉系統',
      '投影設備 － Panasonic RQ50K工程投影機、客製化光瞬膜投影介質',
    ],
    titleText: '設備租賃',
    imgMobile: service_mobile_03,
  },
  {
    title: service_menu_3,
    img: service_bg_3,
    content: ['LED廣告版位 － 全台LED廣告露出'],
    titleText: '廣告合作',
    imgMobile: service_mobile_04,
  },
  {
    title: service_menu_4,
    img: service_bg_4,
    content: ['區塊鏈商城', '智能合約 － 智能合約撰寫 / 審計、NFT開發'],
    titleText: '區塊鏈',
    imgMobile: service_mobile_05,
  },
]

export default data
